.PopUpWindow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
    justify-content: center;
    align-items: center;
    display: block;
    background-color: rgba(0, 0, 0, 0.096);
}